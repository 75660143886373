<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.office')}}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                        <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            :disabled="authOrgId > 0"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.office_type_namel')"
                    label-for="office_type_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.office_type_id"
                        :options="officeList"
                        id="office_type_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.area_type')"
                    label-for="area_type_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.area_type_id"
                        :options="getAreaTypeList"
                        id="area_type_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_division.division')"
                    label-for="division_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_union.union')"
                        label-for="union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.union_id"
                        :options="unionList"
                        id="union_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.office_namel')"
                    label-for="office_name"
                    >
                    <b-form-input
                        id="union_name"
                        v-model="search.office_name"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.office_code')"
                    label-for="office_code"
                    >
                    <b-form-input
                        id="office_code"
                        v-model="search.office_code"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.office') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(office_type_name)="data">
                      <span class="capitalize">{{ data.item.office_type_name }}</span>
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">{{ data.item.district_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(office_name)="data">
                      <span class="capitalize">{{ data.item.office_name }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</span>
                        <span class="badge badge-success" v-else>{{$t('globalTrans.active')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" :item="item"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :item="item"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import { mapGetters } from 'vuex'
import Details from './Details'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { officeEntryList, officeEntryToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV, Details
    },
    data () {
        return {
        search: {
            office_name: '',
            office_code: '',
            area_type_id: '0',
            division_id: '0',
            district_id: '0',
            upazilla_id: '0',
            union_id: '0',
            org_id: '0',
            office_type_id: '0'
        },
        editItemId: '',
        item: '',
        rows: [],
        officeList: [],
        districtList: [],
        upazilaList: [],
        unionList: [],
        authOrgId: 0
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('org_pro.organization'), class: 'text-center' },
                { label: this.$t('org_pro.office_type'), class: 'text-center' },
                { label: this.$t('org_pro.office_code'), class: 'text-center' },
                { label: this.$t('org_pro_district.district'), class: 'text-center' },
                { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
                { label: this.$t('org_pro.office_namel'), class: 'text-center' },
                { label: this.$t('org_pro.area_type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'org_name_bn' },
                    { key: 'office_type_name_bn' },
                    { key: 'office_code' },
                    { key: 'district_name_bn' },
                    { key: 'upazilla_name_bn' },
                    { key: 'office_name_bn' },
                    { key: 'area_type_name_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'org_name' },
                    { key: 'office_type_name' },
                    { key: 'office_code' },
                    { key: 'district_name' },
                    { key: 'upazilla_name' },
                    { key: 'office_name' },
                    { key: 'area_type_name' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        ...mapGetters({
            authUser: 'Auth/authUser'
        })
    },
    watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        },
        'search.org_id': function (newVal, oldVal) {
            this.officeList = this.getOfficeTypeList(newVal)
        },
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'search.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
        }
    },
    created () {
        if (this.$store.state.Auth.activeRoleId !== 1) {
            this.authOrgId = this.authUser.org_id
            this.search = Object.assign({}, this.search, {
                org_id: this.authOrgId
            })
        }
        this.loadData()
    },
    mounted () {
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.org_id
            })
        } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id,
                division_id: this.authUser.office_detail.division_id,
                district_id: this.authUser.office_detail.district_id,
                upazilla_id: this.authUser.office_detail.upazilla_id
            })
        }
        this.loadData()
    },
    methods: {
        searchData () {
            if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.org_id
                })
            } else if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id,
                    division_id: this.authUser.office_detail.division_id,
                    district_id: this.authUser.office_detail.district_id,
                    upazilla_id: this.authUser.office_detail.upazilla_id
                })
            }
            this.loadData()
        },
        edit (item) {
            this.editItemId = item.id
            this.item = item
        },
        details (item) {
            this.item = item
        },
        remove (item) {
            this.changeStatus(commonServiceBaseUrl, officeEntryToggleStatus, item, 'common', 'officeList')
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            RestApi.getData(commonServiceBaseUrl, officeEntryList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        getRelationalData (data) {
            let tmpDivision = {}
            let tmpDistrict = {}
            let tmpUpzila = {}
            let tmpUnion = {}
            let tmpAreaType = {}
            let tmpCityCorporation = {}
            let pauroshoba = {}
            let officeList = {}
            let officeTypeList = {}
            const listData = data.map(item => {
                tmpDivision = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id && division.status === 0)
                tmpDistrict = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id && district.status === 0)
                tmpUpzila = this.$store.state.commonObj.upazilaList.find(upizila => upizila.value === item.upazilla_id && upizila.status === 0)
                tmpUnion = this.$store.state.commonObj.unionList.find(union => union.value === item.union_id && union.status === 0)
                tmpAreaType = this.$store.state.commonObj.wardTypeList.find(areaType => areaType.id === item.area_type_id)
                tmpCityCorporation = this.$store.state.commonObjCommonConfig.cityCorporationList.find(cityC => cityC.value === item.city_corporation_id)
                pauroshoba = this.$store.state.commonObjCommonConfig.pauroshobaList.find(pauroshoba => pauroshoba.value === item.pauroshoba_id)
                officeList = this.$store.state.commonObj.officeList.find(officeType => officeType.value === item.parent_office_id)
                officeTypeList = this.$store.state.commonObj.officeTypeList.find(officeType => officeType.value === item.parent_office_type_id)
                const orgData = {
                    division_name: tmpDivision !== undefined ? tmpDivision.text_en : '',
                    division_name_bn: tmpDivision !== undefined ? tmpDivision.text_bn : '',
                    distict_name: tmpDistrict !== undefined ? tmpDistrict.text_en : '',
                    distict_name_bn: tmpDistrict !== undefined ? tmpDistrict.text_bn : '',
                    upazilla_name: tmpUpzila !== undefined ? tmpUpzila.text_en : '',
                    upazilla_name_bn: tmpUpzila !== undefined ? tmpUpzila.text_bn : '',
                    union_name: tmpUnion !== undefined ? tmpUnion.text_en : '',
                    union_name_bn: tmpUnion !== undefined ? tmpUnion.text_bn : '',
                    area_type_name: tmpAreaType !== undefined ? tmpAreaType.name : '',
                    area_type_name_bn: tmpAreaType !== undefined ? tmpAreaType.name_bn : '',
                    city_corporation_name: tmpCityCorporation !== undefined ? tmpCityCorporation.text_en : '',
                    city_corporation_name_bn: tmpCityCorporation !== undefined ? tmpCityCorporation.text_bn : '',
                    pauroshoba_name: pauroshoba !== undefined ? pauroshoba.text_en : '',
                    pauroshoba_name_bn: pauroshoba !== undefined ? pauroshoba.text_bn : '',
                    parent_office_name: officeList !== undefined ? officeList.text_en : '',
                    parent_office_name_bn: officeList !== undefined ? officeList.text_bn : '',
                    parent_office_type_name: officeTypeList !== undefined ? officeTypeList.text_en : '',
                    parent_office_type_name_bn: officeTypeList !== undefined ? officeTypeList.text_bn : ''
                }
                return Object.assign({}, item, orgData)
            })
            return listData
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        }
    }
}
</script>
