<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.officeEntry = this.item
  },
  data () {
    return {
      officeEntry: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('complain.organization'), val: this.officeEntry.org_name_bn, key1: this.$t('org_pro.office_type'), val1: this.officeEntry.office_type_name_bn },
          { key: this.$t('org_pro.office_code'), val: this.officeEntry.office_code, key1: this.$t('org_pro.area_type'), val1: this.officeEntry.area_type_name_bn },
          { key: this.$t('complain.division'), val: this.officeEntry.division_name_bn, key1: this.$t('complain.district'), val1: this.officeEntry.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.officeEntry.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.officeEntry.union_name_bn },
          { key: this.$t('org_pro.city_corporation'), val: this.officeEntry.city_corporation_name_bn, key1: this.$t('org_pro.pauroshoba'), val1: this.officeEntry.pauroshoba_name_bn },
          { key: this.$t('org_pro.ward'), val: this.officeEntry.ward_name_bn },
          { key: this.$t('org_pro.office_namel'), val: this.officeEntry.office_name_bn, key1: this.$t('org_pro.parent_office_type'), val1: this.officeEntry.parent_office_type_name_bn },
          { key: this.$t('org_pro.parent_office_name'), val: this.officeEntry.parent_office_name_bn }
        ]
      } else {
          return [
          { key: this.$t('complain.organization'), val: this.officeEntry.org_name, key1: this.$t('org_pro.office_type'), val1: this.officeEntry.office_type_name },
          { key: this.$t('org_pro.office_code'), val: this.officeEntry.office_code, key1: this.$t('org_pro.area_type'), val1: this.officeEntry.area_type_name },
          { key: this.$t('complain.division'), val: this.officeEntry.division_name, key1: this.$t('complain.district'), val1: this.officeEntry.district_name },
          { key: this.$t('complain.upazila'), val: this.officeEntry.upazilla_name, key1: this.$t('complain.union'), val1: this.officeEntry.union_name },
          { key: this.$t('org_pro.city_corporation'), val: this.officeEntry.city_corporation_name, key1: this.$t('org_pro.pauroshoba'), val1: this.officeEntry.pauroshoba_name },
          { key: this.$t('org_pro.ward'), val: this.officeEntry.ward_name },
          { key: this.$t('org_pro.office_namel'), val: this.officeEntry.office_name, key1: this.$t('org_pro.parent_office_type'), val1: this.officeEntry.parent_office_type_name },
          { key: this.$t('org_pro.parent_office_name'), val: this.officeEntry.parent_office_name }
          ]
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
