<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row>
                    <b-col xl="12" lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Organization" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :disabled="authOrgId > 0"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="office.org_id === 13">
                                        <ValidationProvider name="Office Category" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_cat_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.office_cat')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.office_cat_id"
                                                :options="officeCategoryList"
                                                id="office_cat_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Office Type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="area_type_id" vid="area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="area_type_id "
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.area_type_id"
                                                id="area_type_id"
                                                :options="getAreaTypeList"
                                                @change="getAreaTypeData(office.area_type_id)"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.division_id"
                                                :options="divisionList"
                                                id="division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                                        <ValidationProvider name="Upazila" vid="upazila_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.upazilla_id"
                                                :options="upazilaList"
                                                id="upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="UnionItemShow">
                                        <ValidationProvider name="Union" vid="union_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="union_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.union_id"
                                                :options="unionList"
                                                id="union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                                        <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="city_corporation_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.city_corporation') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.city_corporation_id"
                                                :options="cityCorporationList"
                                                id="city_corporation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                                        <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="pauroshoba_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.pauroshoba_id"
                                                :options="pauroshobaList"
                                                id="pauroshoba_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="Ward" vid="ward_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="ward_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.ward') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.ward_id"
                                                :options="wardList"
                                                id="ward_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Office name" vid='office_name' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('org_pro.office_name')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="office_name"
                                                v-model="office.office_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Office name (bn)" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('org_pro.office_name_bn')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="office_name_bn"
                                                v-model="office.office_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Address" vid="address">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="address"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.address')}}
                                                </template>
                                                <b-form-input
                                                id="address"
                                                v-model="office.address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Address (bn)" vid="address_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="address_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('org_pro.address_bn')}}
                                            </template>
                                                <b-form-input
                                                id="address_bn"
                                                v-model="office.address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Office code">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                :label="$t('org_pro.office_code')"
                                                label-for="office_code"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <b-form-input
                                                id="office_code"
                                                v-model="office.office_code"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Parent Office Type">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                :label="$t('org_pro.parent_office_type')"
                                                label-for="parent_office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                plain
                                                v-model="office.parent_office_type_id"
                                                :options="officeTypeList"
                                                id="parent_office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Parent Office">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                :label="$t('org_pro.parent_office_name')"
                                                label-for="parent_office_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                plain
                                                v-model="office.parent_office_id"
                                                :options="officeList"
                                                id="parent_office_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12"></b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Is Regional Office" vid="is_regional_office">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="is_regional_office"
                                            >
                                                <template v-slot:label>
                                                    {{$t('org_pro.is_regional_office')}}
                                                </template>
                                                <b-form-radio-group
                                                    v-model="office.is_regional_office"
                                                    :options="manualList"
                                                >
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Regional Office">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                :label="$t('org_pro.regional_office_name')"
                                                label-for="regional_office_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-select
                                                plain
                                                v-model="office.regional_office_id"
                                                :options="regionalOfficeList"
                                                id="regional_office_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :disabled=" regionalStatus"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { officeEntryStore, officeEntryUpdate } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            if (this.item.area_type_id === 1) {
                this.ItemShow = true
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (this.item.area_type_id === 2) {
                this.ItemShow = true
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (this.item.area_type_id === 3) {
                this.ItemShow = true
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
            const tmp = this.getOfficeData()
            // Object.freeze(tmp)
            this.office = tmp
        }
        if (this.$store.state.Auth.activeRoleId !== 1) {
            this.authOrgId = this.$store.state.Auth.authUser.org_id
            this.office = Object.assign({}, this.office, {
                org_id: this.authOrgId
            })
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            officeCatShow: false,
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            office: {
                org_id: '0',
                office_type_id: '0',
                area_type_id: '0',
                city_corporation_id: '0',
                pauroshoba_id: '0',
                ward_id: '0',
                country_id: 1,
                division_id: '0',
                district_id: '0',
                upazilla_id: '0',
                union_id: '0',
                office_name: '',
                office_name_bn: '',
                office_code: '',
                parent_office_type_id: '0',
                parent_office_id: '0',
                is_regional_office: 0,
                regional_office_id: '0',
                created_by: 1,
                updated_by: 1,
                address: '',
                address_bn: '',
                office_cat_id: 0
            },
            regionalStatus: false,
            officeTypeList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            wardList: [],
            officeList: [],
            regionalOfficeList: [],
            officeCategoryList: [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Division' : 'বিভাগ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'District' : 'জেলা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Upazila' : 'উপজেলা' }
            ],
            manualList: [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes' },
                { value: 0, text: this.$i18n.locale === 'bn' ? 'না' : 'No' }
            ],
            authOrgId: 0
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        }
    },
    watch: {
        'office.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'office.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'office.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'office.city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'office.pauroshoba_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByPauroshoba(newVal)
        },
        'office.union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'office.org_id': function (newVal, oldVal) {
            this.officeTypeList = this.getOfficeTypeList(newVal)
            this.regionalOfficeList = this.getRegionalOfficeList(newVal)
        },
        'office.parent_office_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.officeList = this.getParentOfficeList(newVal)
            }
        },
        'office.is_regional_office': function (newVal) {
            if (newVal === 1) {
                this.regionalStatus = true
            } else {
                this.regionalStatus = false
            }
        }
    },
    methods: {
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getOfficeData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
        },
        async register () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: true }

            if (this.id) {
                result = await RestApi.putData(commonServiceBaseUrl, `${officeEntryUpdate}/${this.id}`, this.office)
            } else {
                result = await RestApi.postData(commonServiceBaseUrl, officeEntryStore, this.office)
            }
            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getOfficeTypeList (orgId = null) {
            if (orgId === 13) {
                this.officeCatShow = true
            } else {
                this.officeCatShow = false
            }
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getParentOfficeList (officeTypeId = null) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
            if (officeTypeId) {
                return officeList.filter(office => office.office_type_id === officeTypeId)
            }
            return officeList
        },
        getRegionalOfficeList (orgId = null) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === orgId && item.is_regional_office === 1)
            return officeList
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
    }
}
</script>
